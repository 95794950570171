import React, { useState, useEffect, useRef } from 'react';
import { Menu, X, MapPin, Phone, Mail, Facebook, Instagram } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const TikTokIcon = ({ size = 24, className = "" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}>
        <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5"></path>
    </svg>
);

const Button = ({ variant, size, onClick, children }) => (
    <button
        className={`px-3 py-1 rounded-md transition-colors duration-300 ${variant === 'default' ? 'bg-orange-500 text-white' : 'bg-white text-orange-500 border border-orange-500'
            } ${size === 'sm' ? 'text-sm' : 'text-base'}`}
        onClick={onClick}
    >
        {children}
    </button>
);

const ACCESS_TOKEN = 'IGQWROcWt4b0pvS2steXRlMzdiandMS3J3MExXai1FQS03WkNjMjhEOXNqZAC1YNWRBZA2VQY0JiSFFyT2o1RzlhSUFkS0RULVVIdkdMWHlLaU91RUhMVXI1VnoxZAHZAaUjJqc1lTODBWTW50cm16bGk4UThZASmZA1R3MZD';
const USER_ID = '7786844568027872';

function InstagramFeed() {
    const { t } = useTranslation();
    const [posts, setPosts] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const feedRef = useRef(null);
    const headerHeight = 80;

    const scrollToFeed = () => {
        if (feedRef.current) {
            feedRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
            const offset = window.innerWidth < 768 ? 60 : headerHeight;
            window.scrollBy(0, -offset);
        }
    };

    useEffect(() => {
        fetchInstagramPosts(startIndex);
    }, [startIndex]);

    async function fetchInstagramPosts(index) {
        try {
            const response = await fetch(`https://graph.instagram.com/${USER_ID}/media?fields=id,caption,media_type,media_url,permalink,timestamp,children{media_url,media_type}&access_token=${ACCESS_TOKEN}`);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setPosts(data.data.slice(index, index + 3));
        } catch (error) {
            console.error('Error fetching Instagram posts:', error);
        }
    }

    const handleNext = () => {
        setStartIndex(prevIndex => prevIndex + 3);
        setTimeout(scrollToFeed, 100);
    };

    const handlePrev = () => {
        setStartIndex(prevIndex => Math.max(0, prevIndex - 3));
        setTimeout(scrollToFeed, 100);
    };

    return (
        <section ref={feedRef} className="py-16 bg-gradient-to-r from-orange-100 to-red-100">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-12">{t('ultimas_publicaciones')}</h2>
                <div className="flex flex-col items-center gap-4">
                    {posts.map(post => (
                        <div key={post.id} className="bg-white rounded-lg shadow-md w-full max-w-md">
                            <div className="p-4">
                                <h3 className="font-bold text-lg">{post.caption.split('\n')[0]}</h3>
                            </div>
                            <div className="p-0">
                                {post.media_type === 'VIDEO' ? (
                                    <video src={post.media_url} controls className="w-full h-auto object-cover" />
                                ) : (
                                    <img src={post.media_url} alt={post.caption} className="w-full h-auto object-cover" />
                                )}
                            </div>
                            <div className="p-4 flex flex-col items-start">
                                <p className="text-sm text-gray-600 mb-2">{new Date(post.timestamp).toLocaleDateString()}</p>
                                <button
                                    className="text-blue-600 hover:text-blue-800"
                                    onClick={() => window.open(post.permalink, '_blank')}
                                >
                                    {t('leer_mas')}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                <div className="flex justify-center mt-8 space-x-4">
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50"
                        onClick={handlePrev}
                        disabled={startIndex === 0}
                    >
                        {t('anterior')}
                    </button>
                    <button
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        onClick={handleNext}
                    >
                        {t('siguiente')}
                    </button>
                </div>
            </div>
        </section>
    );
}

export default function HomePage() {
    const { t, i18n } = useTranslation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [countdown, setCountdown] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    const [yearsSinceFoundation, setYearsSinceFoundation] = useState(0);
    const [showVideo, setShowVideo] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const handleLogoClick = (e) => {
        e.preventDefault();
        setShowVideo(true);
    };

    useEffect(() => {
        const targetDate = new Date('2025-03-19T00:00:00').getTime();

        const updateCountdown = () => {
            const now = new Date().getTime();
            const difference = targetDate - now;

            if (difference > 0) {
                setCountdown({
                    days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                    hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                    minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                    seconds: Math.floor((difference % (1000 * 60)) / 1000)
                });
            } else {
                setCountdown({ days: 0, hours: 0, minutes: 0, seconds: 0 });
            }
        };

        const timer = setInterval(updateCountdown, 1000);
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const foundationYear = 1972;
        setYearsSinceFoundation(currentYear - foundationYear);
    }, []);

    const handleClick = (e, path) => {
        e.preventDefault();
        if (path === '/') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            navigate(path);
        }
        setIsMenuOpen(false);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const navItems = [
        { name: t('inicio'), path: "/" },
        { name: t('calendario'), path: "/calendari" },
        { name: t('historia'), path: "/historia" },
        { name: t('presidentes'), path: "/presidents" },
        { name: t('falleras_mayores'), path: "/falleresMajors" },
        { name: t('fallas'), path: "/falles" },
        { name: t('casal'), path: "/casal" },
        { name: t('apuntate'), path: "/apuntat" }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-b from-orange-50 to-red-100 text-gray-900">
            <header className="bg-white shadow-lg sticky top-0 z-50">
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <Link
                        to="/"
                        className="flex items-center"
                        onClick={(e) => handleClick(e, '/')}
                    >
                        <img
                            src="/img/escudo-compress.webp"
                            alt="Escudo de la Falla Padre Méndez"
                            className="h-20 w-20 transition-transform duration-300 hover:scale-110 cursor-pointer"
                            onClick={handleLogoClick}
                        />
                    </Link>
                    <nav className="hidden md:flex space-x-6">
                        {navItems.map((item) => (
                            <Link
                                key={item.name}
                                to={item.path}
                                className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300 border-b-2 border-transparent hover:border-orange-500"
                                onClick={(e) => handleClick(e, item.path)}
                            >
                                {item.name}
                            </Link>
                        ))}
                    </nav>

                    <div className="hidden md:flex items-center space-x-4">
                        <div className="flex space-x-2">
                            <Button
                                variant={i18n.language === 'es' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('es')}
                            >
                                CAS
                            </Button>
                            <Button
                                variant={i18n.language === 'ca' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('ca')}
                            >
                                VAL
                            </Button>
                        </div>
                        <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Facebook size={24} />
                        </a>
                        <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Instagram size={24} />
                        </a>
                        <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <TikTokIcon size={24} />
                        </a>
                    </div>

                    <button
                        className="md:hidden p-2 rounded-md hover:bg-gray-100 transition-colors duration-300"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        aria-label="Toggle menu"
                    >
                        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </div>
                {isMenuOpen && (
                    <div className="md:hidden bg-white py-4 px-4 shadow-inner">
                        <nav className="flex flex-col space-y-4">
                            {navItems.map((item) => (
                                <Link
                                    key={item.name}
                                    to={item.path}
                                    className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300"
                                    onClick={(e) => handleClick(e, item.path)}
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </nav>
                        <hr className="my-4 border-gray-200" />
                        <div className="flex justify-center space-x-4 mt-4">
                            <Button
                                variant={i18n.language === 'es' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('es')}
                            >
                                ES
                            </Button>
                            <Button
                                variant={i18n.language === 'ca' ? "default" : "outline"}
                                size="sm"
                                onClick={() => changeLanguage('ca')}
                            >
                                CA
                            </Button>
                        </div>
                        <div className="flex justify-center space-x-4 mt-4">
                            <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Facebook size={24} />
                            </a>
                            <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Instagram size={24} />
                            </a>
                            <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <TikTokIcon size={24} />
                            </a>
                        </div>
                    </div>
                )}
            </header>

            <main>
                <section className="bg-gradient-to-r from-orange-500 to-red-600 text-white py-20">
                    <div className="container mx-auto px-4 text-center">
                        <img src="/img/movil-compress.webp" alt="Escudo de la Falla Padre Méndez" className="mx-auto h-44 w-44 mb-4 rounded-lg shadow-lg animate-bounce" />
                        <h1 className="text-4xl md:text-6xl font-bold mb-4 animate-fade-in-down">Falla Padre Méndez i Voltants</h1>
                        <p className="text-xl mb-8 animate-fade-in-up">{t('fundada_en')}</p>
                        <button
                            className="bg-white text-black font-bold py-2 px-6 rounded-full hover:bg-primary hover:text-orange-500 transition-colors duration-300 animate-pulse"
                            onClick={() => {
                                const section = document.getElementById("bienvenidos");
                                section.scrollIntoView({ behavior: "smooth", block: "start" });
                                const headerOffset = document.querySelector('header').offsetHeight;
                                const elementPosition = section.getBoundingClientRect().top;
                                const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                                window.scrollTo({
                                    top: offsetPosition,
                                    behavior: "smooth"
                                });
                            }}
                        >
                            {t('descubre_mas')}
                        </button>
                    </div>
                </section>

                <section id="bienvenidos" className="py-16 bg-white">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-8">{t('bienvenidos')}</h2>
                        <div className="max-w-3xl mx-auto text-lg leading-relaxed space-y-6">
                            <p className="animate-fade-in">{t('bienvenidos_p1')}</p>
                            <p className="animate-fade-in">{t('bienvenidos_p2')}</p>
                            <p className="animate-fade-in">{t('bienvenidos_p3')}</p>
                        </div>
                    </div>
                </section>

                <section className="py-16 bg-gradient-to-r from-orange-100 to-red-100">
                    <div className="container mx-auto px-4 text-center">
                        <h2 className="text-3xl font-bold mb-8">{t('falles')} <span className="text-primary">2025</span></h2>
                        <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                            {Object.entries(countdown).map(([unit, value]) => (
                                <div key={unit} className="bg-white rounded-lg shadow-md p-6 transform hover:scale-105 transition-transform duration-300">
                                    <div className="text-4xl font-bold text-primary mb-2">{value}</div>
                                    <div className="text-gray-600 capitalize">{t(unit)}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="py-16 bg-white">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">{t('representantes')} <span className="text-primary">2025</span></h2>
                        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
                            {[
                                { cargo: t('fallera_mayor'), nombre: "Marisol Merchante Castillo", image: "/img/marisol-compress.webp?height=300&width=200" },
                                { cargo: t('presidenta'), nombre: "Pilar Carratalá Pascual", image: "/img/presidenta-compress.webp?height=300&width=200" },
                                { cargo: t('fallera_mayor_infantil'), nombre: "Carlota Sanz Merchante", image: "/placeholder.svg?height=300&width=200" },
                                { cargo: t('presidente_infantil'), nombre: "Francisco Javier Galán Noguera", image: "/placeholder.svg?height=300&width=200" }
                            ].map((representante) => (
                                <div key={representante.cargo} className="bg-white rounded-lg shadow-md overflow-hidden transform hover:scale-105 transition-transform duration-300 flex flex-col">
                                    <div className="flex-shrink-0">
                                        <img
                                            src={representante.image}
                                            alt={representante.nombre}
                                            className="w-full object-cover"
                                            style={{ height: '300px', objectPosition: 'top' }}
                                        />
                                    </div>
                                    <div className="p-4 flex flex-col justify-center h-32">
                                        <h3 className="font-bold text-lg mb-2 text-primary">{representante.cargo}</h3>
                                        <p className="text-gray-600">{representante.nombre}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="py-16 bg-gradient-to-r from-orange-100 to-red-100">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">{t('datos_historicos')}</h2>
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
                            {[
                                { title: t('anos'), number: yearsSinceFoundation },
                                { title: t('comision'), number: '+400' },
                                { title: t('fallera_mayor_torrent'), number: 1 },
                                { title: t('fallera_mayor_infantil_torrent'), number: 1 },
                                { title: t('corte_honor'), number: 5 },
                                { title: t('presidentes'), number: 15 }
                            ].map((dato) => (
                                <div key={dato.title} className="bg-white rounded-lg shadow-md p-6 text-center transform hover:scale-105 transition-transform duration-300">
                                    <div className="text-4xl font-bold text-primary mb-2">{dato.number}</div>
                                    <div className="text-gray-600">{dato.title}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <section className="py-16 bg-white">
                    <div className="container mx-auto px-4">
                        <h2 className="text-3xl font-bold text-center mb-12">{t('monumentos')} <span className="text-primary">2024!</span></h2>
                        <div className="grid md:grid-cols-2 gap-8">
                            {[
                                { title: t('monumento_mayor'), lema: "Mare Meua", artista: "Dani Barea", image: "/img/fmajor-compress.webp?height=400&width=300" },
                                { title: t('monumento_infantil'), lema: "La més dolça", artista: "Dani Barea", image: "/img/finfantil-compress.webp?height=400&width=300" }
                            ].map((monument) => (
                                <div key={monument.title} className="bg-white rounded-lg shadow-md overflow-hidden transform hover:scale-105 transition-transform duration-300">
                                    <img src={monument.image} alt={monument.title} className="w-full h-64 object-scale-down" />
                                    <div className="p-6">
                                        <h3 className="font-bold text-xl mb-2 text-primary">{monument.title}</h3>
                                        <p className="text-gray-600 mb-2">{t('lema')}: {monument.lema}</p>
                                        <p className="text-gray-600">{t('artista')}: {monument.artista}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>

                <InstagramFeed />

            </main>

            <footer className="bg-gradient-to-r from-orange-600 to-red-600 text-white py-12">
                <div className="container mx-auto px-4">
                    <div className="grid md:grid-cols-3 gap-8">
                        <div>
                            <img src="/img/escudo-compress.webp" alt="escudo de la Falla Padre Méndez" className="h-24 w-auto mb-4 rounded-full border-2 border-white" />
                            <p className="text-sm">Falla Padre Méndez © 2024</p>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">{t('contacto')}</h3>
                            <ul className="space-y-2">
                                <li className="flex items-center">
                                    <MapPin size={18} className="mr-2" />
                                    <span>c/ Padre Méndez, 78, 46900 Torrent, Valencia</span>
                                </li>
                                <li className="flex items-center">
                                    <Phone size={18} className="mr-2" />
                                    <span>+34 </span>
                                </li>
                                <li className="flex items-center">
                                    <Mail size={18} className="mr-2" />
                                    <a href="mailto:secretariofallapadremendez@gmail.com" className="hover:text-orange-200 transition-colors duration-300">
                                        secretariofallapadremendez@gmail.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">{t('siguenos')}</h3>
                            <div className="flex space-x-4">
                                <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="hover:text-orange-200 transition-colors duration-300">
                                    <Facebook size={24} />
                                </a>
                                <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="hover:text-orange-200 transition-colors duration-300">
                                    <Instagram size={24} />
                                </a>
                                <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="hover:text-orange-200 transition-colors duration-300">
                                    <TikTokIcon size={24} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {showVideo && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <div className="relative w-full max-w-4xl">
                        <button
                            className="absolute top-4 right-4 text-white text-2xl"
                            onClick={() => setShowVideo(false)}
                        >
                            &times;
                        </button>
                        <video
                            src="/video/logo.mp4"
                            autoPlay
                            playsInline
                            className="w-full"
                            onClick={() => setShowVideo(false)}
                            onEnded={() => setShowVideo(false)}
                        >
                            {t('video_not_supported')}
                        </video>
                    </div>
                </div>
            )}
        </div>
    );
}