'use client'

import React, { useState, useEffect } from 'react'
import { Menu, X, MapPin, Phone, Mail, Facebook, Instagram } from 'lucide-react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

const TikTokIcon = ({ size = 24, className = "" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}>
        <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5"></path>
    </svg>
)

const monuments = [
    { year: "2024", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2024-compress.webp?height=300&width=200", childImage: "/img/MNI2024-compress.webp?height=300&width=200" },
    { year: "2023", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2023-compress.webp?height=300&width=200", childImage: "/img/MNI2023-compress.webp?height=300&width=200" },
    { year: "2022", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2022-compress.webp?height=300&width=200", childImage: "/img/MNI2022-compress.webp?height=300&width=200" },
    { year: "2020-2021", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2020-compress.webp?height=300&width=200", childImage: "/img/MNI2020-compress.webp?height=300&width=200" },
    { year: "2019", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2019-compress.webp?height=300&width=200", childImage: "/img/MNI2019-compress.webp?height=300&width=200" },
    { year: "2018", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2018-compress.webp?height=300&width=200", childImage: "/img/MNI2018-compress.webp?height=300&width=200" },
    { year: "2017", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2017-compress.webp?height=300&width=200", childImage: "/img/MNI2017-compress.webp?height=300&width=200" },
    { year: "2016", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2016-compress.webp?height=300&width=200", childImage: "/img/MNI2016-compress.webp?height=300&width=200" },
    { year: "2015", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2015-compress.webp?height=300&width=200", childImage: "/img/MNI2015-compress.webp?height=300&width=200" },
    { year: "2014", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2014-compress.webp?height=300&width=200", childImage: "/img/MNI2014-compress.webp?height=300&width=200" },
    { year: "2013", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2013-compress.webp?height=300&width=200", childImage: "/img/MNI2013-compress.webp?height=300&width=200" },
    { year: "2012", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2012-compress.webp?height=300&width=200", childImage: "/img/MNI2012-compress.webp?height=300&width=200" },
    { year: "2011", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2011-compress.webp?height=300&width=200", childImage: "/img/MNI2011-compress.webp?height=300&width=200" },
    { year: "2010", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2010-compress.webp?height=300&width=200", childImage: "/img/MNI2010-compress.webp?height=300&width=200" },
    { year: "2009", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2009-compress.webp?height=300&width=200", childImage: "/img/MNI2009-compress.webp?height=300&width=200" },
    { year: "2008", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2008-compress.webp?height=300&width=200", childImage: "/img/MNI2008-compress.webp?height=300&width=200" },
    { year: "2007", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2007-compress.webp?height=300&width=200", childImage: "/img/MNI2007-compress.webp?height=300&width=200" },
    { year: "2006", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2006-compress.webp?height=300&width=200", childImage: "/img/MNI2006-compress.webp?height=300&width=200" },
    { year: "2005", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2005-compress.webp?height=300&width=200", childImage: "/img/MNI2005-compress.webp?height=300&width=200" },
    { year: "2004", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2004-compress.webp?height=300&width=200", childImage: "/img/MNI2004-compress.webp?height=300&width=200" },
    { year: "2003", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2003-compress.webp?height=300&width=200", childImage: "/img/MNI2003-compress.webp?height=300&width=200" },
    { year: "2002", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2002-compress.webp?height=300&width=200", childImage: "/img/MNI2002-compress.webp?height=300&width=200" },
    { year: "2001", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2001-compress.webp?height=300&width=200", childImage: "/img/MNI2001-compress.webp?height=300&width=200" },
    { year: "2000", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM2000-compress.webp?height=300&width=200", childImage: "/img/MNI2000-compress.webp?height=300&width=200" },
    { year: "1999", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1999-compress.webp?height=300&width=200", childImage: "/img/MNI1999-compress.webp?height=300&width=200" },
    { year: "1998", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1998-compress.webp?height=300&width=200", childImage: "/img/MNI1998-compress.webp?height=300&width=200" },
    { year: "1997", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1997-compress.webp?height=300&width=200", childImage: "/img/MNI1997-compress.webp?height=300&width=200" },
    { year: "1996", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1996-compress.webp?height=300&width=200", childImage: "/img/MNI1996-compress.webp?height=300&width=200" },
    { year: "1995", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1995-compress.webp?height=300&width=200", childImage: "/img/MNI1995-compress.webp?height=300&width=200" },
    { year: "1994", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1994-compress.webp?height=300&width=200", childImage: "/img/MNI1994-compress.webp?height=300&width=200" },
    { year: "1993", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1993-compress.webp?height=300&width=200", childImage: "/img/MNI1993-compress.webp?height=300&width=200" },
    { year: "1992", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1992-compress.webp?height=300&width=200", childImage: "/img/MNI1992-compress.webp?height=300&width=200" },
    { year: "1991", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1991-compress.webp?height=300&width=200", childImage: "/img/MNI1991-compress.webp?height=300&width=200" },
    { year: "1990", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1990-compress.webp?height=300&width=200", childImage: "/img/MNI1990-compress.webp?height=300&width=200" },
    { year: "1989", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1989-compress.webp?height=300&width=200"},
    { year: "1988", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1988-compress.webp?height=300&width=200", childImage: "/img/MNI1988-compress.webp?height=300&width=200" },
    { year: "1987", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1987-compress.webp?height=300&width=200", childImage: "/img/MNI1987-compress.webp?height=300&width=200" },
    { year: "1986", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1986-compress.webp?height=300&width=200", childImage: "/img/MNI1986-compress.webp?height=300&width=200" },
    { year: "1985", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1985-compress.webp?height=300&width=200", childImage: "/img/MNI1985-compress.webp?height=300&width=200" },
    { year: "1984", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1984-compress.webp?height=300&width=200", childImage: "/img/MNI1984-compress.webp?height=300&width=200" },
    { year: "1983", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1983-compress.webp?height=300&width=200", childImage: "/img/MNI1983-compress.webp?height=300&width=200" },
    { year: "1982", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1982-compress.webp?height=300&width=200", childImage: "/img/MNI1982-compress.webp?height=300&width=200" },
    { year: "1981", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1981-compress.webp?height=300&width=200", childImage: "/img/MNI1981-compress.webp?height=300&width=200" },
    { year: "1980", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1980-compress.webp?height=300&width=200"},
    { year: "1979", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1979-compress.webp?height=300&width=200"},
    { year: "1978", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1978-compress.webp?height=300&width=200"},
    { year: "1977", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1977-compress.webp?height=300&width=200"},
    { year: "1976", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1976-compress.webp?height=300&width=200"},
    { year: "1975", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1975-compress.webp?height=300&width=200"},
    { year: "1974", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1974-compress.webp?height=300&width=200"},
    { year: "1973", adult: "Lema Monument Major", child: "Lema Monument Infantil", adultImage: "/img/MNM1973-compress.webp?height=300&width=200" },
];  

const MonumentsPage = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    const handleClick = (e, path) => {
        e.preventDefault()
        if (path === '/' || path === '/falles') {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
        if (path !== location.pathname) {
            navigate(path)
        }
        setIsMenuOpen(false)
    }

    const navItems = [
        { name: "Inici", path: "/" },
        { name: "Calendari", path: "/calendari" },
        { name: "Història", path: "/historia" },
        { name: "Presidents", path: "/presidents" },
        { name: "Falleres Majors", path: "/falleresMajors" },
        { name: "Falles", path: "/falles" },
        { name: "Casal", path: "/casal" },
        { name: "Apunta't", path: "/apuntat" }
    ]

    return (
        <div className="min-h-screen bg-gradient-to-b from-orange-50 to-red-100 text-gray-900">
            <header className="bg-white shadow-lg sticky top-0 z-50">
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <Link
                        to="/"
                        className="flex items-center"
                        onClick={(e) => handleClick(e, '/')}
                    >
                        <img src="/img/escudo-compress.webp" alt="Escudo de la Falla Padre Méndez" className="h-20 w-20 transition-transform duration-300 hover:scale-110" />
                    </Link>
                    <nav className="hidden md:flex space-x-6">
                        {navItems.map((item) => (
                            <Link 
                                key={item.name} 
                                to={item.path} 
                                className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300 border-b-2 border-transparent hover:border-orange-500"
                                onClick={(e) => handleClick(e, item.path)}
                            >
                                {item.name}
                            </Link>
                        ))}
                    </nav>

                    <div className="hidden md:flex space-x-4">
                        <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Facebook size={24} />
                        </a>
                        <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Instagram size={24} />
                        </a>
                        <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <TikTokIcon size={24} />
                        </a>
                    </div>

                    <button
                        className="md:hidden p-2 rounded-md hover:bg-gray-100 transition-colors duration-300"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        aria-label="Toggle menu"
                    >
                        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </div>
                {isMenuOpen && (
                    <div className="md:hidden bg-white py-4 px-4 shadow-inner">
                        <nav className="flex flex-col space-y-4">
                            {navItems.map((item) => (
                                <Link 
                                    key={item.name} 
                                    to={item.path} 
                                    className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300"
                                    onClick={(e) => handleClick(e, item.path)}
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </nav>
                        <hr className="my-4 border-gray-200" />
                        <div className="flex justify-center space-x-4 mt-4">
                            <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Facebook size={24} />
                            </a>
                            <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Instagram size={24} />
                            </a>
                            <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <TikTokIcon size={24} />
                            </a>
                        </div>
                    </div>
                )}
            </header>

            <main className="py-16 bg-gradient-to-r from-orange-100 to-red-100">
                <div className="container mx-auto px-4">
                    <h1 className="text-4xl md:text-5xl font-bold text-center mb-12 bg-gradient-to-r from-orange-500 to-red-600 text-transparent bg-clip-text transition-transform duration-300 hover:scale-110">
                        Monuments
                    </h1>
                    <div className="space-y-16">
                        {monuments.map((monument, index) => (
                            <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:shadow-lg">
                                <div className="p-6">
                                    <h2 className="text-3xl font-bold text-center mb-6">{monument.year}</h2>
                                    <div className={`grid ${monument.childImage ? 'md:grid-cols-2' : 'md:grid-cols-1'} gap-8`}>
                                        <div className="space-y-4">
                                            <img src={monument.adultImage} alt={`Monumento Major ${monument.year}`} 
                                            className="w-full h-auto max-w-56 mx-auto object-cover rounded-lg shadow-md" />
                                            <div className="text-center">
                                                <h3 className="text-xl font-semibold">Monument Major</h3>
                                                <p className="text-lg">{monument.adult}</p>
                                            </div>
                                        </div>
                                        {monument.childImage && (
                                            <div className="space-y-4">
                                                <img src={monument.childImage} alt={`Monumento Infantil ${monument.year}`} 
                                                className="w-full h-auto max-w-56 mx-auto object-cover rounded-lg shadow-md" />
                                                <div className="text-center">
                                                    <h3 className="text-xl font-semibold">Monument Infantil</h3>
                                                    <p className="text-lg">{monument.child}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main>

            <footer className="bg-gradient-to-r from-orange-600 to-red-600 text-white py-12">
                <div className="container mx-auto px-4">
                    <div className="grid md:grid-cols-3 gap-8">
                        <div>
                            <img src="/img/escudo-compress.webp" alt="escudo de la Falla Padre Méndez" className="h-24 w-auto mb-4 rounded-full border-2 border-white" />
                            <p className="text-sm">Falla Padre Méndez © {new Date().getFullYear()}</p>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Contacte</h3>
                            <ul className="space-y-2">
                                <li className="flex items-center">
                                    <MapPin size={18} className="mr-2" />
                                    <span>c/ Padre Méndez, 78, 46900 Torrent, Valencia</span>
                                </li>
                                <li className="flex items-center">
                                    <Phone size={18} className="mr-2" />
                                    <span>+34 </span>
                                </li>
                                <li className="flex items-center">
                                    <Mail size={18} className="mr-2" />
                                    <a href="mailto:secretariofallapadremendez@gmail.com" className="hover:text-orange-200 transition-colors duration-300">
                                        secretariofallapadremendez@gmail.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Segueix-nos</h3>
                            <div className="flex space-x-4">
                                <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="hover:text-orange-200 transition-colors duration-300">
                                    <Facebook size={24} />
                                </a>
                                <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="hover:text-orange-200 transition-colors duration-300">
                                    <Instagram size={24} />
                                </a>
                                <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="hover:text-orange-200 transition-colors duration-300">
                                    <TikTokIcon size={24} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default MonumentsPage