'use client'

import React, { useState, useEffect } from 'react'
import { Menu, X, MapPin, Phone, Mail, Facebook, Instagram } from 'lucide-react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

const TikTokIcon = ({ size = 24, className = "" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}>
        <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5"></path>
    </svg>
)

export default function FundacioPage() {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [modalSrc, setModalSrc] = useState('')

    const navigate = useNavigate();
    const location = useLocation();

    const handleClick = (e, path) => {
        e.preventDefault();
        if (path === '/' || path === '/historia') {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (path !== location.pathname) {
            navigate(path);
        }
        setIsMenuOpen(false);
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    const navItems = [
        { name: "Inici", path: "/" },
        { name: "Calendari", path: "/calendari" },
        { name: "Història", path: "/historia" },
        { name: "Presidents", path: "/presidents" },
        { name: "Falleres Majors", path: "/falleresMajors" },
        { name: "Falles", path: "/falles" },
        { name: "Casal", path: "/casal" },
        { name: "Apunta't", path: "/apuntat" }
    ];

    const openModal = (src) => {
        setModalSrc(src)
        setModalOpen(true)
    }

    return (
        <div className="min-h-screen bg-gradient-to-b from-orange-50 to-red-100 text-gray-900">
            <header className="bg-white shadow-lg sticky top-0 z-50">
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <Link
                        to="/"
                        className="flex items-center"
                        onClick={(e) => handleClick(e, '/')}
                    >
                        <img src="/img/escudo-compress.webp" alt="Escudo de la Falla Padre Méndez" className="h-20 w-20 transition-transform duration-300 hover:scale-110" />
                    </Link>
                    <nav className="hidden md:flex space-x-6">
                        {navItems.map((item) => (
                            <Link 
                                key={item.name} 
                                to={item.path} 
                                className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300 border-b-2 border-transparent hover:border-orange-500"
                                onClick={(e) => handleClick(e, item.path)}
                            >
                                {item.name}
                            </Link>
                        ))}
                    </nav>

                    <div className="hidden md:flex space-x-4">
                        <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Facebook size={24} />
                        </a>
                        <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Instagram size={24} />
                        </a>
                        <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <TikTokIcon size={24} />
                        </a>
                    </div>

                    <button
                        className="md:hidden p-2 rounded-md hover:bg-gray-100 transition-colors duration-300"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        aria-label="Toggle menu"
                    >
                        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </div>
                {isMenuOpen && (
                    <div className="md:hidden bg-white py-4 px-4 shadow-inner">
                        <nav className="flex flex-col space-y-4">
                            {navItems.map((item) => (
                                <Link 
                                    key={item.name} 
                                    to={item.path} 
                                    className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300"
                                    onClick={(e) => handleClick(e, item.path)}
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </nav>
                        <hr className="my-4 border-gray-200" />
                        <div className="flex justify-center space-x-4 mt-4">
                            <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Facebook size={24} />
                            </a>
                            <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Instagram size={24} />
                            </a>
                            <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <TikTokIcon size={24} />
                            </a>
                        </div>
                    </div>
                )}
            </header>
            <main className="container mx-auto px-4 py-8">
                <h1 className="text-4xl md:text-5xl font-bold text-center mb-8 text-transparent bg-clip-text bg-gradient-to-r from-orange-500 to-red-600 animate-fade-in-down">
                    Fundació
                </h1>

                <div className="mb-8 animate-fade-in">
                    <img src="/img/1acta-compress.webp" alt="Fundació" className="w-full max-w-3xl mx-auto rounded-lg shadow-lg" />
                </div>

                <div className="space-y-6 text-gray-700 max-w-3xl mx-auto animate-fade-in">
                    <p>
                        PADRE MÉNDEZ, l'antic carrer de pila, que servia d'abeurador per als animals que passaven per allí,
                        era un dels carrers principals de Torrent, la qual va créixer molt de pressa.
                    </p>
                    <p>
                        Molts dels xiquets nascuts allí van créixer i van anar forman un gran grup d'amics.
                    </p>
                    <p>
                        A Torrent ja s'havien format 11 falles quan un d'aqueixos grups d'amics es reunien cada vesprada
                        en el pub "la rata", que van decidir formar una falla al carrer de la seva infància, i així gaudir
                        de les falles de Torrent.
                    </p>
                    <p>
                        Molts no estan entre nosaltres, uns altres ens segixen ja des de la barrera i altres pocs segueixen
                        al nostre costat... Per això volem resaltar que gràcies a ells hui podem dir "jo soc faller/a
                        de Padre Méndez".
                    </p>
                    <p>
                        Amb molt d'afecte volem nomenar aquells que van ser els fundadors, els primers en voler fer falla i
                        encara que no apareixen en la foto de signatura d'aqueixa primera acta i contracte amb l'artista,
                        estaven allí ajudant i aportant tot el que es podia per a poder formar la nostra benvolguda comissió.
                    </p>
                </div>

                <ul className="list-none text-center space-y-2 my-8 animate-fade-in">
                    {[
                        "Isidro", "Melquiades", "Tio Pepe", "Sebastian", "Paco 'el de la rata'",
                        "Cantero", "Pepe Company", "Jose Perpiñá", "Salvador Company", "Manuel Isarte",
                        "Conejeros", "Enrique Cervera", "Jose Sanz 'el obispo'", "Angel Gonzalez", "Paco Gonzalez"
                    ].map((name, index) => (
                        <li key={index} className="text-gray-700">{name}</li>
                    ))}
                </ul>

                <div className="space-y-6 text-gray-700 max-w-3xl mx-auto mb-12 animate-fade-in">
                    <p>
                        A partir d'aquest moment van ser molts els que es van anar unint a la comissió arribant a ser uns
                        70 membres.
                    </p>
                    <p>
                        Vam nàixer com un grup d'amics, que va passar a ser una gran família, per acabar convertint-se
                        en la gran falla de hui en dia.
                    </p>
                </div>

                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 animate-fade-in">
                    {[1, 2, 3, 4].map((num) => (
                        <img
                            key={num}
                            src={`/img/fundacio${num}-compress.webp`}
                            alt={`f${num}`}
                            className="w-full rounded-lg shadow-md cursor-pointer transition-transform hover:scale-105"
                            onClick={() => openModal(`fundacio${num}.jpg`)}
                        />
                    ))}
                </div>
            </main>

            <footer className="bg-gradient-to-r from-orange-600 to-red-600 text-white py-12">
                <div className="container mx-auto px-4">
                    <div className="grid md:grid-cols-3 gap-8">
                        <div>
                            <img src="/img/escudo-compress.webp" alt="escudo de la Falla Padre Méndez" className="h-24 w-auto mb-4 rounded-full border-2 border-white" />
                            <p className="text-sm">Falla Padre Méndez © 2024</p>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Contacte</h3>
                            <ul className="space-y-2">
                                <li className="flex items-center">
                                    <MapPin size={18} className="mr-2" />
                                    <span>c/ Padre Méndez, 78, 46900 Torrent, Valencia</span>
                                </li>
                                <li className="flex items-center">
                                    <Phone size={18} className="mr-2" />
                                    <span>+34 </span>
                                </li>
                                <li className="flex items-center">
                                    <Mail size={18} className="mr-2" />
                                    <a href="mailto:secretariofallapadremendez@gmail.com" className="hover:text-orange-200 transition-colors duration-300">
                                        secretariofallapadremendez@gmail.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Segueix-nos</h3>
                            <div className="flex space-x-4">
                                <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="hover:text-orange-200 transition-colors duration-300">
                                    <Facebook size={24} />
                                </a>
                                <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="hover:text-orange-200 transition-colors duration-300">
                                    <Instagram size={24} />
                                </a>
                                <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="hover:text-orange-200 transition-colors duration-300">
                                    <TikTokIcon size={24} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            {modalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    onClick={() => setModalOpen(false)}
                >
                    <div
                        className="bg-white p-4 rounded-lg flex items-center justify-center overflow-hidden"
                        style={{
                            maxWidth: '90vw',
                            maxHeight: '90vh',
                        }}
                    >
                        <img
                            src={modalSrc}
                            alt="Imagen ampliada"
                            className="w-auto h-auto max-w-full max-h-full object-contain"
                        />
                    </div>
                </div>
            )}

        </div>
    )
}