'use client'

import React, { useState, useEffect, useCallback } from 'react'
import { Menu, X, MapPin, Phone, Mail, Facebook, Instagram } from 'lucide-react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

const TikTokIcon = ({ size = 24, className = "" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}>
        <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5"></path>
    </svg>
)

const presidents = [
    { id: 1, name: "Jose Perpiña Pla", term: "(1973-1977 1988-1989)", image: "/img/Perpina-compress.webp" },
    { id: 2, name: "Francisco Fernandez Castellanos", term: "(1978-1979 1982-1983)", image: "/img/Incognita-compress.webp" },
    { id: 3, name: "Angel Gonzalez Navarro", term: "(1980)", image: "/img/AngelGonzalez-compress.webp" },
    { id: 4, name: "Alfredo Ros Martinez", term: "(1981)", image: "/img/Incognita-compress.webp" },
    { id: 5, name: "Jose Garcia Cervera", term: "(1984 1987)", image: "/img/Incognita-compress.webp" },
    { id: 6, name: "Antonio Soriano Soriano", term: "(1985-1986)", image: "/img/AntonioSoriano-compress.webp" },
    { id: 7, name: "Manuel Cruz Granada", term: "(1990-1991 1995-1998)", image: "/img/ManoloCruz-compress.webp" },
    { id: 8, name: "Julio Panadero Hernandez", term: "(1992)", image: "/img/Incognita-compress.webp" },
    { id: 9, name: "Salvador Company Vilanova", term: "(1993-1994)", image: "/img/Incognita-compress.webp" },
    { id: 10, name: "Jose Antonio Ramón Moreno", term: "(1999-2000)", image: "/img/JoseARamon-compress.webp" },
    { id: 11, name: "Santiago Ruiz De Elvira Martinez", term: "(2001-2003 2008-2009)", image: "/img/SantiagoRuiz-compress.webp" },
    { id: 12, name: "Jose Sanz Ramos", term: "(2004-2007 2017-2019)", image: "/img/JoseSanz-compress.webp" },
    { id: 13, name: "Jose Luis Del Toro Sanz", term: "(2010-2013 2017-2019)", image: "/img/Bou-compress.webp" },
    { id: 14, name: "Felipe Mantas Javier", term: "(2014-2016)", image: "/img/FelipeMantas-compress.webp" },
    { id: 15, name: "Pilar Carratalà Pascual", term: "(2020-2024)", image: "/img/Pilar-compress.webp" },
]

export default function PresidentsPage() {
    const [isMenuOpen, setIsMenuOpen] = useState(false)
    const [selectedPresident, setSelectedPresident] = useState(null)
    const [sortOrder, setSortOrder] = useState('asc')
    const [sortedPresidents, setSortedPresidents] = useState([...presidents])
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)

    const navigate = useNavigate()
    const location = useLocation()

    const handleClick = (e, path) => {
        e.preventDefault()
        if (path === '/' || path === '/presidents') {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }
        if (path !== location.pathname) {
            navigate(path)
        }
        setIsMenuOpen(false)
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [])

    const navItems = [
        { name: "Inici", path: "/" },
        { name: "Calendari", path: "/calendari" },
        { name: "Història", path: "/historia" },
        { name: "Presidents", path: "/presidents" },
        { name: "Falleres Majors", path: "/falleresMajors" },
        { name: "Falles", path: "/falles" },
        { name: "Casal", path: "/casal" },
        { name: "Apunta't", path: "/apuntat" }
    ]

    const openModal = (president) => {
        setSelectedPresident(president)
    }

    const closeModal = useCallback(() => {
        setSelectedPresident(null)
    }, [])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                closeModal()
            }
        }

        window.addEventListener('keydown', handleKeyDown)

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [closeModal])

    useEffect(() => {
        const sorted = [...presidents].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a.id - b.id
            } else {
                return b.id - a.id
            }
        })
        setSortedPresidents(sorted)
    }, [sortOrder])

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen)
    }

    return (
        <div className="min-h-screen bg-gradient-to-b from-orange-50 to-red-100 text-gray-900">
            <header className="bg-white shadow-lg sticky top-0 z-50">
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <Link
                        to="/"
                        className="flex items-center"
                        onClick={(e) => handleClick(e, '/')}
                    >
                        <img src="/img/escudo-compress.webp" alt="Escudo de la Falla Padre Méndez" className="h-20 w-20 transition-transform duration-300 hover:scale-110" />
                    </Link>
                    <nav className="hidden md:flex space-x-6">
                        {navItems.map((item) => (
                            <Link 
                                key={item.name} 
                                to={item.path} 
                                className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300 border-b-2 border-transparent hover:border-orange-500"
                                onClick={(e) => handleClick(e, item.path)}
                            >
                                {item.name}
                            </Link>
                        ))}
                    </nav>

                    <div className="hidden md:flex space-x-4">
                        <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Facebook size={24} />
                        </a>
                        <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Instagram size={24} />
                        </a>
                        <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <TikTokIcon size={24} />
                        </a>
                    </div>

                    <button
                        className="md:hidden p-2 rounded-md hover:bg-gray-100 transition-colors duration-300"
                        onClick={() => setIsMenuOpen(!isMenuOpen)}
                        aria-label="Toggle menu"
                    >
                        {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </div>
                {isMenuOpen && (
                    <div className="md:hidden bg-white py-4 px-4 shadow-inner">
                        <nav className="flex flex-col space-y-4">
                            {navItems.map((item) => (
                                <Link 
                                    key={item.name} 
                                    to={item.path} 
                                    className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300"
                                    onClick={(e) => handleClick(e, item.path)}
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </nav>
                        <hr className="my-4 border-gray-200" />
                        <div className="flex justify-center space-x-4 mt-4">
                            <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Facebook size={24} />
                            </a>
                            <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Instagram size={24} />
                            </a>
                            <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <TikTokIcon size={24} />
                            </a>
                        </div>
                    </div>
                )}
            </header>
            <main className="py-16 bg-gradient-to-r from-orange-100 to-red-100">
                <div className="container mx-auto px-4">
                    <h1 className="text-4xl md:text-5xl font-bold text-center mb-12 bg-gradient-to-r from-orange-500 to-red-600 text-transparent bg-clip-text transition-transform duration-300 hover:scale-110">
                        Presidents
                    </h1>
                    <div className="flex justify-end mb-4 relative">
                        <button
                            onClick={toggleDropdown}
                            className="bg-white text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow hover:bg-gray-100"
                        >
                            Ordenar
                            <svg className="w-4 h-4 ml-2 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </button>
                        {isDropdownOpen && (
                            <div className="absolute right-0 mt-12 w-48 bg-white rounded-md shadow-lg z-10">
                                <button
                                    onClick={() => {
                                        setSortOrder('asc')
                                        setIsDropdownOpen(false)
                                    }}
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                >
                                    Primers 
                                </button>
                                <button
                                    onClick={() => {
                                        setSortOrder('desc')
                                        setIsDropdownOpen(false)
                                    }}
                                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                                >
                                    Últims
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
                        {sortedPresidents.map((president) => (
                            <div
                                key={president.id}
                                className="bg-white rounded-lg shadow-md overflow-hidden transition-all duration-300 hover:scale-105 hover:shadow-lg relative cursor-pointer"
                                onClick={() => openModal(president)}
                            >
                                <div className="p-6">
                                    <div className="mb-4 w-48 h-48 mx-auto rounded-full overflow-hidden border-4 border-white shadow-lg">
                                        <img
                                            src={president.image}
                                            alt={president.name}
                                            className="w-full h-full object-cover"
                                        />
                                    </div>
                                    <div className="text-center">
                                        <h3 className="font-bold text-lg mb-2">{president.name}</h3>
                                        <p className="text-gray-600">{president.term}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </main>

            <footer className="bg-gradient-to-r from-orange-600 to-red-600 text-white py-12">
                <div className="container mx-auto px-4">
                    <div className="grid md:grid-cols-3 gap-8">
                        <div>
                            <img src="/img/escudo-compress.webp" alt="escudo de la Falla Padre Méndez" className="h-24 w-auto mb-4 rounded-full border-2 border-white" />
                            <p className="text-sm">Falla Padre Méndez © {new Date().getFullYear()}</p>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Contacte</h3>
                            <ul className="space-y-2">
                                <li className="flex items-center">
                                    <MapPin size={18} className="mr-2" />
                                    <span>c/ Padre Méndez, 78, 46900 Torrent, Valencia</span>
                                </li>
                                <li className="flex items-center">
                                    <Phone size={18} className="mr-2" />
                                    <span>+34 </span>
                                </li>
                                <li className="flex items-center">
                                    <Mail size={18} className="mr-2" />
                                    <a href="mailto:secretariofallapadremendez@gmail.com" className="hover: text-orange-200 transition-colors duration-300">
                                        secretariofallapadremendez@gmail.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Segueix-nos</h3>
                            <div className="flex space-x-4">
                                <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="hover:text-orange-200 transition-colors duration-300">
                                    <Facebook size={24} />
                                </a>
                                <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="hover:text-orange-200 transition-colors duration-300">
                                    <Instagram size={24} />
                                </a>
                                <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="hover:text-orange-200 transition-colors duration-300">
                                    <TikTokIcon size={24} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

            {selectedPresident && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-4"
                    onClick={closeModal}
                >
                    <div
                        className="relative max-w-4xl w-full h-full flex items-center justify-center"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button
                            onClick={closeModal}
                            className="absolute top-4 right-4 text-white hover:text-gray-300 transition-colors duration-300"
                            aria-label="Close modal"
                        >
                            <X size={24} />
                        </button>
                        <img
                            src={selectedPresident.image}
                            alt={selectedPresident.name}
                            className="max-w-full max-h-full object-contain"
                        />
                    </div>
                </div>
            )}
        </div>
    )
}