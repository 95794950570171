import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import HomePage from './HomePage'
import CalendariPage from './CalendariPage'
import Fundacio from './Fundacio'
import PresidentsPage from './PresidentsPage'
import FalleresMajoresPage from './FalleresMajoresPage'
import MonumentsPage from './MonumentsPage'
import CasalPage from './CasalPage'  
import ApuntatPage from './ApuntatPage'
import './i18n.js'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/calendari" element={<CalendariPage />} />
        <Route path="/historia" element={<Fundacio />} />
        <Route path="/presidents" element={<PresidentsPage />} />
        <Route path="/falleresMajors" element={<FalleresMajoresPage />} />
        <Route path="/falles" element={<MonumentsPage />} />
        <Route path="/casal" element={<CasalPage />} />
        <Route path="/apuntat" element={<ApuntatPage />} />
      </Routes>
    </Router>
  )
}

// Movemos el renderizado a un archivo separado (index.js)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

export default App