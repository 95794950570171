'use client'

import React, { useState, useEffect } from 'react';
import { Menu, X, MapPin, Phone, Mail, Facebook, Instagram } from 'lucide-react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const TikTokIcon = ({ size = 24, className = "" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}>
    <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5"></path>
  </svg>
);

export default function CalendariPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (e, path) => {
    e.preventDefault();
    if (path === '/' || path === '/calendari') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    if (path !== location.pathname) {
      navigate(path);
    }
    setIsMenuOpen(false);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    // Load iFrameResize script
    const script = document.createElement('script');
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.min.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      // Initialize iFrameResize
      window.iFrameResize({
        heightCalculationMethod: 'lowestElement',
        checkOrigin: ['https://embed.styledcalendar.com']
      }, '.styled-calendar-container');

      // Add click event listener
      document.body.addEventListener('click', () => {
        document.querySelectorAll('iframe.styled-calendar-container').forEach(iframe => {
          if (iframe.contentWindow) {
            iframe.contentWindow.postMessage({ type: 'styled_calendar_parent_click' }, 'https://embed.styledcalendar.com');
          }
        });
      });

      // Set up interval to resize iframes
      setInterval(() => {
        const iframes = document.querySelectorAll('iframe.styled-calendar-container');
        Array.from(iframes).filter(iframe => 'iFrameResizer' in iframe).forEach(iframe => iframe.iFrameResizer.resize());
      }, 1000);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const navItems = [
    { name: "Inici", path: "/" },
    { name: "Calendari", path: "/calendari" },
    { name: "Història", path: "/historia" },
    { name: "Presidents", path: "/presidents" },
    { name: "Falleres Majors", path: "/falleresMajors" },
    { name: "Falles", path: "/falles" },
    { name: "Casal", path: "/casal" },
    { name: "Apunta't", path: "/apuntat" }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-orange-50 to-red-100 text-gray-900">
      <header className="bg-white shadow-lg sticky top-0 z-50">
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Link
            to="/"
            className="flex items-center"
            onClick={(e) => handleClick(e, '/')}
          >
            <img src="/img/escudo-compress.webp" alt="Escudo de la Falla Padre Méndez" className="h-20 w-20 transition-transform duration-300 hover:scale-110" />
          </Link>
          <nav className="hidden md:flex space-x-6">
            {navItems.map((item) => (
              <Link 
                key={item.name} 
                to={item.path} 
                className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300 border-b-2 border-transparent hover:border-orange-500"
                onClick={(e) => handleClick(e, item.path)}
              >
                {item.name}
              </Link>
            ))}
          </nav>

          <div className="hidden md:flex space-x-4">
            <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
              <Facebook size={24} />
            </a>
            <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
              <Instagram size={24} />
            </a>
            <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
              <TikTokIcon size={24} />
            </a>
          </div>

          <button
            className="md:hidden p-2 rounded-md hover:bg-gray-100 transition-colors duration-300"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
        {isMenuOpen && (
          <div className="md:hidden bg-white py-4 px-4 shadow-inner">
            <nav className="flex flex-col space-y-4">
              {navItems.map((item) => (
                <Link 
                  key={item.name} 
                  to={item.path} 
                  className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300"
                  onClick={(e) => handleClick(e, item.path)}
                >
                  {item.name}
                </Link>
              ))}
            </nav>
            <hr className="my-4 border-gray-200" />
            <div className="flex justify-center space-x-4 mt-4">
              <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                <Facebook size={24} />
              </a>
              <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                <Instagram size={24} />
              </a>
              <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                <TikTokIcon size={24} />
              </a>
            </div>
          </div>
        )}
      </header>

      <main className="py-16 bg-gradient-to-r from-orange-100 to-red-100">
        <div className="container mx-auto px-4">
          <h1 className="text-4xl md:text-5xl font-bold text-center mb-12 bg-gradient-to-r from-orange-500 to-red-600 text-transparent bg-clip-text transition-transform duration-300 hover:scale-110">
            Calendari
          </h1>
          <p className="text-center mb-8">Fes click al botó de "Sincronitzar" per agregar els events del calendari al teu Google Calendar.</p>
          <div className="calendar-container">
            <iframe
              src="https://embed.styledcalendar.com/#HqPdEQ2lXBPjwYMWpENW"
              title="Styled Calendar"
              className="styled-calendar-container"
              style={{ width: '100%', border: 'none', minHeight: '600px' }}
              data-cy="calendar-embed-iframe"
            ></iframe>
          </div>
          <div className="mt-8 text-center">

          </div>
          {events.length > 0 && (
            <div className="mt-8 bg-white shadow-lg rounded-lg p-6">
              <h2 className="text-2xl font-semibold mb-4">Próximos eventos</h2>
              {events.map((event, index) => (
                <div key={index} className="mb-4 p-4 bg-orange-100 rounded-lg">
                  <h3 className="font-bold">{event.summary}</h3>
                  <p>{new Date(event.start.dateTime).toLocaleString()}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </main>

      <footer className="bg-gradient-to-r from-orange-600 to-red-600 text-white py-12">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8">
            <div>
              <img src="/img/escudo-compress.webp" alt="escudo de la Falla Padre Méndez" className="h-24 w-auto mb-4 rounded-full border-2 border-white" />
              <p className="text-sm">Falla Padre Méndez © {new Date().getFullYear()}</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Contacte</h3>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <MapPin size={18} className="mr-2" />
                  <span>c/ Padre Méndez, 78, 46900 Torrent, Valencia</span>
                </li>
                <li className="flex items-center">
                  <Phone size={18} className="mr-2" />
                  <span>+34 </span>
                </li>
                <li className="flex items-center">
                  <Mail size={18} className="mr-2" />
                  <a href="mailto:secretariofallapadremendez@gmail.com" className="hover:text-orange-200 transition-colors duration-300">
                    secretariofallapadremendez@gmail.com
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-4">Segueix-nos</h3>
              <div className="flex space-x-4">
                <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="hover:text-orange-200 transition-colors duration-300">
                  <Facebook size={24} />
                </a>
                <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="hover:text-orange-200 transition-colors duration-300">
                  <Instagram size={24} />
                </a>
                <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="hover:text-orange-200 transition-colors duration-300">
                  <TikTokIcon size={24} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}