import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            es: {
                translation: {
                    "inicio": "Inicio",
                    "calendario": "Calendario",
                    "historia": "Historia",
                    "presidentes": "Presidentes",
                    "falleras_mayores": "Falleras Mayores",
                    "fallas": "Fallas",
                    "casal": "Casal",
                    "apuntate": "Apúntate",
                    "descubre_mas": "Descubre más",
                    "bienvenidos": "Bienvenidos a la Falla Padre Méndez y Alrededores de Torrent",
                    "fundada_en": "Fundada en 1972 - Más de 50 años de tradición y fiesta",
                    "ultimas_publicaciones": "Últimas Publicaciones",
                    "leer_mas": "Leer más",
                    "anterior": "Anterior",
                    "siguiente": "Siguiente",
                    "contacto": "Contacto",
                    "siguenos": "Síguenos",
                    "dias": "días",
                    "horas": "horas",
                    "minutos": "minutos",
                    "segundos": "segundos",
                    "fallera_mayor": "Fallera Mayor",
                    "presidenta": "Presidenta",
                    "fallera_mayor_infantil": "Fallera Mayor Infantil",
                    "presidente_infantil": "Presidente Infantil",
                    "anos": "Años",
                    "comision": "Comisión",
                    "fallera_mayor_torrent": "Fallera Mayor de Torrent",
                    "fallera_mayor_infantil_torrent": "Fallera Mayor Infantil de Torrent",
                    "corte_honor": "Corte de honor",
                    "monumento_mayor": "Monumento Mayor",
                    "monumento_infantil": "Monumento Infantil",
                    "lema": "Lema",
                    "artista": "Artista",
                    "representantes": "Representantes",
                    "bienvenidos_p1": "Desde 1972, nuestra comisión ha sido un punto de encuentro para vecinos y vecinas de todas las edades, unidos por la pasión por las Fallas. Hemos vivido momentos inolvidables, creando recuerdos que perdurarán para siempre en nuestra historia.",
                    "bienvenidos_p2": "Año tras año, nos esforzamos por hacer de las Fallas una experiencia única. Nuestros monumentos, llenos de ingenio y crítica, son una muestra del talento y la creatividad de nuestros artistas falleros. Pero las Fallas son mucho más que eso: son música, pólvora, gastronomía, hermandad... y sobre todo, son nuestra gente.",
                    "bienvenidos_p3": "Te invitamos a que nos conozcas, a que participes en nuestras actividades y a que descubras todo lo que nuestra comisión puede ofrecerte. ¡Ven a vivir las Fallas con nosotros!",
                    "monumentos": "Monumentos",
                    "datos_historicos": "Datos históricos",
                    "falles": "Fallas",
                    "days": "Días",
                    "hours": "Horas",
                    "minutes": "Minutos",
                    "seconds": "Segundos"
                }
            },
            ca: {
                translation: {
                    "inicio": "Inici",
                    "calendario": "Calendari",
                    "historia": "Història",
                    "presidentes": "Presidents",
                    "falleras_mayores": "Falleres Majors",
                    "fallas": "Falles",
                    "casal": "Casal",
                    "apuntate": "Apunta't",
                    "descubre_mas": "Descobreix més",
                    "bienvenidos": "Benvinguts a la Falla Pare Méndez i Voltants de Torrent",
                    "fundada_en": "Fundada al 1972 - Més de 50 anys de tradició i festa",
                    "ultimas_publicaciones": "Últimes Publicacions",
                    "leer_mas": "Llegir més",
                    "anterior": "Anterior",
                    "siguiente": "Següent",
                    "contacto": "Contacte",
                    "siguenos": "Segueix-nos",
                    "dias": "dies",
                    "horas": "hores",
                    "minutos": "minuts",
                    "segundos": "segons",
                    "fallera_mayor": "Fallera Major",
                    "presidenta": "Presidenta",
                    "fallera_mayor_infantil": "Fallera Major Infantil",
                    "presidente_infantil": "President Infantil",
                    "anos": "Anys",
                    "comision": "Comissió",
                    "fallera_mayor_torrent": "Fallera Major de Torrent",
                    "fallera_mayor_infantil_torrent": "Fallera Major Infantil de Torrent",
                    "corte_honor": "Cort d'honor",
                    "monumento_mayor": "Monument Major",
                    "monumento_infantil": "Monument Infantil",
                    "lema": "Lema",
                    "artista": "Artista",
                    "representantes": "Representants",
                    "bienvenidos_p1": "Des de 1972, la nostra comissió ha sigut un punt de trobada per a veïns i veïnes de totes les edats, units per la passió per les Falles. Hem viscut moments inoblidables, creant records que perduraran per sempre en la nostra història.",
                    "bienvenidos_p2": "Any rere any, ens esforcem per a fer de les Falles una experiència única. Els nostres monuments, plens d'enginy i crítica, són una mostra del talent i la creativitat dels nostres artistes fallers. Però les Falles són molt més que això: són música, pólvora, gastronomia, germanor... i sobretot, són la nostra gent.",
                    "bienvenidos_p3": "Et convidem a que ens coneixes, a que participes en les nostres activitats i a que descobrisques tot el que la nostra comissió pot oferir-te. ¡Vine a viure les Falles amb nosaltres!",
                    "monumentos": "Monuments",
                    "datos_historicos": "Dades històriques",
                    "falles": "Falles",
                    "days": "Dies",
                    "hours": "Hores",
                    "minutes": "Minuts",
                    "seconds": "Segons"
                }
            }
        },
        fallbackLng: "es",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;