'use client'

import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MapPin, Phone, Mail, Facebook, Instagram, X, Menu, MapIcon } from 'lucide-react';

const IconoTikTok = ({ size = 24, className = "" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className}>
        <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5"></path>
    </svg>
);

export default function PaginaCasal() {
    const [menuAbierto, setMenuAbierto] = useState(false);
    const refMapa = useRef(null);
    const refScript = useRef(null);

    useEffect(() => {
        if (!window.google) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyBxYSHhy1Rsmh8heD9CONv7pWzufKAvLlA&callback=initMap`;
            script.async = true;
            script.defer = true;
            refScript.current = script;

            window.initMap = inicializarMapa;
            document.head.appendChild(script);
        } else {
            inicializarMapa();
        }

        return () => {
            if (refScript.current && document.head.contains(refScript.current)) {
                document.head.removeChild(refScript.current);
            }
            delete window.initMap;
        };
    }, []);

    function inicializarMapa() {
        if (refMapa.current && window.google) {
            const ubicacionCasal = { lat: 39.43387407494986, lng: -0.46824730608356246 };
            const mapa = new window.google.maps.Map(refMapa.current, {
                center: ubicacionCasal,
                zoom: 15,
            });
            new window.google.maps.Marker({
                position: ubicacionCasal,
                map: mapa,
            });
        }
    }

    function abrirGoogleMaps() {
        const ubicacionCasal = { lat: 39.43370300364448, lng: -0.46817440118211434 };
        const url = `https://www.google.com/maps/dir/?api=1&destination=${ubicacionCasal.lat},${ubicacionCasal.lng}`;
        window.open(url, '_blank');
    }

    const navegar = useNavigate();

    const manejarClic = (e) => {
        e.preventDefault();
        navegar('/');
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 100);
    };

    const elementosNav = [
        { nombre: "Inicio", ruta: "/" },
        { nombre: "Calendario", ruta: "/calendario" },
        { nombre: "Historia", ruta: "/historia" },
        { nombre: "Presidentes", ruta: "/presidentes" },
        { nombre: "Falleras Mayores", ruta: "/fallerasMayores" },
        { nombre: "Fallas", ruta: "/fallas" },
        { nombre: "Casal", ruta: "/casal" },
        { nombre: "Apúntate", ruta: "/apuntate" }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-b from-orange-50 to-red-100 text-gray-900">
            <header className="bg-white shadow-lg sticky top-0 z-50">
                <div className="container mx-auto px-4 py-4 flex justify-between items-center">
                    <Link
                        to="/"
                        className="flex items-center"
                        onClick={manejarClic}
                    >
                        <img src="/img/escudo-compress.webp" alt="Escudo de la Falla Padre Méndez" className="h-20 w-20 transition-transform duration-300 hover:scale-110" />
                    </Link>
                    <nav className="hidden md:flex space-x-6">
                        {elementosNav.map((item) => (
                            <Link key={item.nombre} to={item.ruta} className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300 border-b-2 border-transparent hover:border-orange-500">
                                {item.nombre}
                            </Link>
                        ))}
                    </nav>

                    <div className="hidden md:flex space-x-4">
                        <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Facebook size={24} />
                        </a>
                        <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <Instagram size={24} />
                        </a>
                        <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                            <IconoTikTok size={24} />
                        </a>
                    </div>

                    <button
                        className="md:hidden p-2 rounded-md hover:bg-gray-100 transition-colors duration-300"
                        onClick={() => setMenuAbierto(!menuAbierto)}
                        aria-label="Alternar menú"
                    >
                        {menuAbierto ? <X size={24} /> : <Menu size={24} />}
                    </button>
                </div>
                {menuAbierto && (
                    <div className="md:hidden bg-white py-4 px-4 shadow-inner">
                        <nav className="flex flex-col space-y-4">
                            {elementosNav.map((item) => (
                                <Link key={item.nombre} to={item.ruta} className="text-gray-700 hover:text-orange-500 font-semibold transition-colors duration-300">
                                    {item.nombre}
                                </Link>
                            ))}
                        </nav>
                        <hr className="my-4 border-gray-200" />
                        <div className="flex justify-center space-x-4 mt-4">
                            <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Facebook size={24} />
                            </a>
                            <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <Instagram size={24} />
                            </a>
                            <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="text-gray-600 hover:text-orange-500 transition-colors duration-300">
                                <IconoTikTok size={24} />
                            </a>
                        </div>
                    </div>
                )}
            </header>

            <main>
                <section className="py-16 bg-white">
                    <div className="container mx-auto px-4">
                        <h1 className="text-4xl font-bold text-center mb-8">Casal <span className="text-orange-500">el Cresol</span></h1>
                        
                        <div className="mb-12">
                            <p className="text-2xl text-center mb-4">Ubicación del casal:</p>
                            <div ref={refMapa} className="h-96 mb-4 rounded-lg shadow-lg"></div>
                            <div className="text-center mt-4">
                                <button 
                                    onClick={abrirGoogleMaps} 
                                    className="inline-flex items-center justify-center px-4 py-2 rounded-md bg-white text-orange-500 border-2 border-orange-500 hover:bg-orange-500 hover:text-white transition-all duration-300 ease-out focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-opacity-50"
                                    aria-label="Cómo llegar al casal"
                                >
                                    <MapIcon className="w-5 h-5 mr-2" />
                                    <span>Cómo llegar</span>
                                </button>
                            </div>
                        </div>

                        <div className="text-center mb-12">
                            <p className="text-2xl mb-4">Foto del casal:</p>
                            <figure className="inline-block">
                                <img src="/img/casal-compress.webp" alt="Casal El Cresol" className="w-full max-w-2xl mx-auto rounded-lg shadow-lg" />
                                <figcaption className="mt-4 text-gray-600 max-w-2xl mx-auto">
                                    Nuestro querido casal, punto de encuentro para todos los falleros y falleras.
                                </figcaption>
                            </figure>
                        </div>

                        <div className="text-center mb-12 max-w-3xl mx-auto">
                            <h2 className="text-3xl font-bold mb-4">El Cresol</h2>
                            <p className="text-xl italic mb-4 text-orange-500">"Buenas noches cresol, que la luz se apaga."</p>
                            <p className="text-lg">
                                El cresol (en castellano candil o crisol), es un recipiente construido en material refractario utilizado para fundir sustancias a temperaturas muy elevadas. También ha sido utilizado antiguamente para hacer luz colocando dentro del cresol aceite y un hilo que se enciende y da luz. Lámpara de metal dentro de la cual se pone aceite e hilo que se enciende.
                            </p>
                        </div>
                    </div>
                </section>
            </main>

            <footer className="bg-gradient-to-r from-orange-600 to-red-600 text-white py-12">
                <div className="container mx-auto px-4">
                    <div className="grid md:grid-cols-3 gap-8">
                        <div>
                            <img src="/img/escudo-compress.webp" alt="Escudo de la Falla Padre Méndez" className="h-24 w-auto mb-4 rounded-full border-2 border-white" />
                            <p className="text-sm">Falla Padre Méndez © 2024</p>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Contacto</h3>
                            <ul className="space-y-2">
                                <li className="flex items-center">
                                    <MapPin size={18} className="mr-2" />
                                    <span>c/ Padre Méndez, 78, 46900 Torrent, Valencia</span>
                                </li>
                                <li className="flex items-center">
                                    <Phone size={18} className="mr-2" />
                                    <span>+34 </span>
                                </li>
                                <li className="flex items-center">
                                    <Mail size={18} className="mr-2" />
                                    <a href="mailto:secretariofallapadremendez@gmail.com" className="hover:text-orange-200 transition-colors duration-300">
                                        secretariofallapadremendez@gmail.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="text-lg font-semibold mb-4">Síguenos</h3>
                            <div className="flex space-x-4">
                                <a href="https://www.facebook.com/fallaparemendez?locale=es_ES" aria-label="Facebook" className="hover:text-orange-200 transition-colors duration-300">
                                    <Facebook size={24} />
                                </a>
                                <a href="https://www.instagram.com/fallaparemendez/" aria-label="Instagram" className="hover:text-orange-200 transition-colors duration-300">
                                    <Instagram size={24} />
                                </a>
                                <a href="https://www.tiktok.com/@fallaparemendez" aria-label="TikTok" className="hover:text-orange-200 transition-colors duration-300">
                                    <IconoTikTok size={24} />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}